import React from 'react'
import { Facebook, Instagram, Twitter } from '@icons-pack/react-simple-icons';
import { icon } from '../styles/socials.module.scss';

const socialData = [
    {
        name: `Facebook`,
        shortName: `FB`,
        url: `https://www.facebook.com/milo.w.johnson`,
        icon: <Facebook className={icon} />
    },
    {
        name: `Instagram`,
        shortName: `IG`,
        url: `https://www.instagram.com/milo_w_johnson/?hl=en`,
        icon: <Instagram className={icon} />
    },
    {
        name: `Twitter`,
        shortName: `TW`,
        url: null,
        icon: <Twitter className={icon} />
    }
]

export default socialData