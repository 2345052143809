import React from 'react'
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby"
import { brand } from "../styles/brand.module.scss"
import Mwj from "../svg/Initials"

export default function Brand(props) {
    const data = useStaticQuery(graphql`
    query BrandQuery {
      site {
        siteMetadata {
          title
          shortTitle
        }
      }
    }
  `)

    const {as: Component, className, useInitials, ...rest } = props;

    let brandClassName = `${brand} m-0 leading-none`;
    if (className) brandClassName = [brandClassName, className].join(" ");

    return (

        <Component className={brandClassName}>
            <Link to={`/`} title={`Milo Walker Johnson`} {...rest} className={`inline-block`}>
                {
                useInitials === false ? (
                  data.site.siteMetadata.shortTitle
                ) : (
                  <Mwj className={`mx-auto relative max-w-[140px] md:max-w-[165px] my-0.5`} />
                )
                
                
                }
            </Link>
        </Component>
    )
}

Brand.defaultProps = {
    as: 'h1',
    useInitials: false
}