import React from 'react'
import { Spotify, Apple, Youtube, Tidal, Amazon, Bandcamp, Soundcloud, Pandora, Deezer } from '@icons-pack/react-simple-icons';
import { icon } from '../styles/socials.module.scss';

const streamData = [
    {
        name: `Spotify`,
        shortName: `SP`,
        url: `https://open.spotify.com/artist/4v6wVvYesQIu6CXzLZNqyZ`,
        icon: <Spotify className={icon} />
    },
    {
        name: `Apple Music`,
        shortName: `AM`,
        url: `https://music.apple.com/ca/artist/milo-walker-johnson/1642798226`,
        icon: <Apple className={icon} />
    },
    {
        name: `YouTube`,
        shortName: `YT`,
        url: `https://music.youtube.com/channel/UCWzQbNkJcTyn9zus-WFSLhQ?feature=share`,
        icon: <Youtube className={icon} />
    },
    {
        name: `Tidal`,
        shortName: `TD`,
        url: null,
        icon: <Tidal className={icon} />
    },
    {
        name: `Amazon Music`,
        shortName: `AZ`,
        url: `https://music.amazon.ca/artists/B0BCMNZJ9T/milo-walker-johnson`,
        icon: <Amazon className={icon} />
    },
    {
        name: `SoundCloud`,
        shortName: `SC`,
        url: `https://soundcloud.com/milowjohnson`,
        icon: <Soundcloud className={icon} />
    },
    {
        name: `Bandcamp`,
        shortName: `BC`,
        url: `https://milowalkerjohnson.bandcamp.com/releases`,
        icon: <Bandcamp className={icon} />
    },
    {
        name: `Pandora`,
        shortName: `PD`,
        url: null,
        icon: <Pandora className={icon} />
    },
    {
        name: `Deezer`,
        shortName: `DZ`,
        url: `https://www.deezer.com/us/artist/181824407`,
        icon: <Deezer className={icon} />
    }
]

export default streamData