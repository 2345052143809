// extracted by mini-css-extract-plugin
export var alert = "forms-module--alert--+yoP7";
export var dummyUrlField = "forms-module--dummy-url-field--2JpVn";
export var error = "forms-module--error--gFhP4";
export var fadeLoop = "forms-module--fade-loop--9yXfq";
export var footerForm = "forms-module--footer-form--8GnOh";
export var form = "forms-module--form--MnPK5";
export var inSubmission = "forms-module--in-submission--udm6w";
export var inputField = "forms-module--input-field--xNAwA";
export var labelText = "forms-module--label-text--XzOS+";
export var selectField = "forms-module--select-field--GDxfr";
export var selectWrapper = "forms-module--select-wrapper--VIUvt";
export var sending = "forms-module--sending--H--X9";
export var submitButton = "forms-module--submit-button--vHOYh";
export var success = "forms-module--success--dfhEh";
export var textareaField = "forms-module--textarea-field--LcvWR";