import * as React from 'react'
import Container from './Container'
import ContactForm from './ContactForm';
// import { footerForm } from "../styles/forms.module.scss"
import MiloStreamingLinks, { MiloSocialLinks } from './MiloStreamingLinks';

export default function Footer(props) {

    const { siteTitle } = props;

    // const footerStyle = {
    //     backgroundColor: 'var(--color-primary)'
    // }

    const [isConnect, pageIsConnect] = React.useState(false);

    // React.useEffect(() => {
    //     isConnect ? (
    //       window.location.includes('connect')
    //     ) : (
    //       document.body.style.overflowY = 'unset'
    //     )
    //  }, [isOpen]);

    

    React.useEffect(() => {
       if (window.location.pathname.includes('connect') ) {
           pageIsConnect(true);
       } else {
           pageIsConnect(false);
       }
     }, [pageIsConnect]);

    

    return (
        <footer className={`bg-transparent text-prose px-2`} id="connect">
        <Container size={`lg`} className={`pb-10`}>

                {!isConnect &&
                
                <div className={`flex flex-col md:flex-row md:flex-nowrap flex-wrap md:-m-5 md:items-center items-start`}>
                        {/* <div className={`flex-true md:p-5 md:pb-5 pb-10 md:text-center`}>
                            <div className={`md:inline-block md:text-center md:px-16 lg:px-20 xl:px-24`}>
                                <h3 className={`font-bold text-5xl`}>Connect</h3>
                                <p className={`tracking-wider md:max-w-[12.5rem] italic leading-7 mx-auto`}>
                                    Milo can be reached via the contact form provided .
                                </p>
                            </div>
                        </div> */}
                        <div className={`flex-true md:p-5 md:pb-5 pb-5 md:text-center`}>
                        <h3 className={`text-4xl md:text-4xl lg:-text-5xl`}>Connect</h3>
                                <p className={`tracking-wide md:max-w-[12.5rem] leading-5 mx-auto`}>
                                    Milo can be reached via the contact form provided.
                                </p>
                        </div>
                        <div className={`flex-true md:flex-[65%] lg:flex-true md:p-5 md:pb-5`}>
                            <ContactForm className={`max-w-2xl mx-auto`} />
                        </div>

                </div>
                
                }

                <div className={`mt-20 md:flex md:flex-row md:justify-between md:items-start`}>
                    <div className={`md:block hidden`}>
                        <p className={`md:mb-0 text-xl xl:text-2xl font-bold text-accent font-brand`}>
                            {siteTitle.split(" ").map((word, index) => <span className={`block text-left`} key={index}>{word}</span>)}
                        </p>
                    </div>
                    <div>
                    <MiloStreamingLinks className={`-m-2 md:-m-3 lg:-m-4 md:justify-end justify-center`} linkClassName={`p-2 md:p-3 lg:p-4`} header={`Stream`} />
                    <MiloSocialLinks className={`md:pt-0 pt-5 -m-2 md:-m-3 lg:-m-4 md:justify-end justify-center pt-2`} linkClassName={`p-2 md:p-3 lg:p-4`} header={`Follow`} />
                    </div>
                </div>

                {/* <div className={`mt-20`}>
                    <MiloStreamingLinks className={`-m-1 md:-m-2 lg:-m-3 md:justify-end justify-center`} linkClassName={`p-1 md:p-2 lg:p-3`} header={`Stream`} />
                    <MiloSocialLinks className={`-m-1 md:-m-2 lg:-m-3 md:justify-end justify-center pt-2`} linkClassName={`p-1 md:p-2 lg:p-3`} header={`Follow`} />
                </div> */}
                
                
            
            <p className={`m-0 mt-8 uppercase text-center text-sm tracking-widest md:text-right`}>
            &copy; {new Date().getFullYear()} {siteTitle}
            </p>
        </Container>
    </footer>
    )
}