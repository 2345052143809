import React from 'react'
import streamData from '../data/stream-data';
import socialData from '../data/social-media';

export default function MiloStreamingLinks(props) {
    const { linkStyle = "icon" } = props;

    let wrapperClassName = "flex flex-row";
    if (props.className) wrapperClassName = [wrapperClassName, props.className].join(" ");
    let innerClassName = null;
    if (props.linkClassName) innerClassName = props.linkClassName;

    return (

        <div className={wrapperClassName}>

            {props.header &&
            <div className={`md:p-2 lg:p-3 md:block hidden self-center`}>
                <span className={`text-xxs uppercase pr-2 tracking-[0.1rem] font-medium`}>{props.header}</span>
            </div>
            }

            {streamData.map((social, index) => {

                let titleAttr = `Stream Milo Walker Johnson on ${social.name}`;

                if ( ! social.url ) return null

                return (

                    <span className={innerClassName} key={index}>
                        <a className={`hover:opacity-75`} href={social.url} target={`_blank`} rel={`noopener noreferrer`} title={titleAttr}>
                            { linkStyle === "icon" ? social.icon : social.name }
                        </a>
                    </span>
                )
            })}

        </div>
    )
}

export const MiloSocialLinks = props => {
    const { linkStyle = "icon" } = props;

    let wrapperClassName = "flex flex-row";
    if (props.className) wrapperClassName = [wrapperClassName, props.className].join(" ");
    let innerClassName = null;
    if (props.linkClassName) innerClassName = props.linkClassName;

    return (

        <div className={wrapperClassName}>

            {props.header &&
            <div className={`md:p-2 lg:p-3 md:block hidden self-center`}>
                <span className={`text-xxs uppercase pr-2 tracking-[0.1rem] font-medium`}>{props.header}</span>
            </div>
            }

            {socialData.map((social, index) => {

                let titleAttr = `Followm Milo Walker Johnson on ${social.name}`;

                if ( ! social.url ) return null

                return (

                    <span className={innerClassName} key={index}>
                        <a className={`hover:opacity-75`} href={social.url} target={`_blank`} rel={`noopener noreferrer`} title={titleAttr}>
                            { linkStyle === "icon" ? social.icon : social.name }
                        </a>
                    </span>
                    )
            })}

        </div>
    )
}