import React from 'react'
import { container, containerSmall, containerMedium, containerLarge, containerExtraSmall } from '../styles/container.module.scss'

export default function Container( { size, className, children, ...rest } ) {

    if (!children) return null;

    const getSize = () => {
       return  (
        (size === "small" || size === "sm")  ? containerSmall : (size === "medium" || size === "md")  ? containerMedium :
        (size === "large" || size === "lg")  ? containerLarge : (size === "extra-small" || size === "xs") ? containerExtraSmall : 'px-0'
       )
    }

    const containerSize = getSize();

    let containerClassName = containerSize;

    if (className) {
        containerClassName = [containerSize, className].join(' ');
    }


    return (
        <div className={`mx-auto w-full ${containerClassName} ${container}`}>
            <div {...rest}>

                {children}

            </div>

        </div>
    )

}