import React from 'react'

export const PageTitle = props => {

    const { as: Component, className, size, children, ...rest } = props;

    let pageTitleClassName = `-mb-2`;
    if (className) pageTitleClassName = [pageTitleClassName, className].join(" ");
    let titleSize = 'text-6xl';
    if (size) titleSize = size;

    return (

        <div className={pageTitleClassName}>
            <div {...rest}>
                <Component className={`${titleSize} leading-none`}>{children}</Component>
            </div>
        </div>
    )
}

PageTitle.defaultProps = {
    as: `h1`
}

export const SectionTitle = props => {

    const { as:Component, className, children, ...rest } = props;

    let sectionTitleClassName = `-mb-2`;
    if (className) sectionTitleClassName = [sectionTitleClassName, className].join(" ");


    return (

        <div className={sectionTitleClassName}>

            <div {...rest}>
                <Component className={`text-3xl font-medium leading-none uppercase tracking-normal`}>{children}</Component>
            </div>

        </div>
    )
}

SectionTitle.defaultProps = {
    as: `h2`
}