import * as React from 'react'
import { header, mobileMenuWrapper, mobileMenu, mobileLink, activeLink, enterFrom, enterTo, artistLinks } from "../styles/header.module.scss"
import Container from "./Container"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Transition } from "@headlessui/react";
import Brand from "./Brand"
// import { useMediaQuery } from 'react-responsive'
import socialData from '../data/social-media';
import streamData from '../data/stream-data';

const Menu = props => {

    const { menuLinks, siteTitle } = props;

    // const aboveMd = useMediaQuery({ query: '(min-width: 768px)' });

    const [isOpen, setIsOpen] = React.useState(false);

    // const toggleMenu = () => setIsOpen(!isOpen);

    React.useEffect(() => {
        isOpen ? (
          document.body.style.overflowY = 'hidden'
        ) : (
          document.body.style.overflowY = 'unset'
        )
     }, [isOpen]);


    return (
        <>
        <header className={header}>
            <Container size={`lg`} className={`sm:py-2 py-3`}>
                <div className={`flex flex-row items-center justify-between`}>
                <div className={`text-left space-x-1.5 flex-true text-xxs z-[1500] ${isOpen ? 'text-contrast' : 'currentColor'}`}>
                {socialData.map((social, index) => {

                  if (! social.url ) return null;

                    return (
                       <span key={index} className={`space-x-1.5`}>
                         
                         {(index ? <span className={`transition-all duration-200`}>/</span> : '')}
                         <a className={`uppercase text-current`} title={`Follow ${siteTitle} on ${social.name}`} href={social.url} target={`_blank`} rel={`noopener noreferrer`}>
                             {social.shortName}
                         </a>
                         
                         
                       </span>
                    )
                })}
                </div>
                <Brand useInitials={false} className={`flex-true text-center z-[5000]`} style={{color: isOpen ? 'var(--background-color)' : 'var(--color-accent)', transitionDuration: '200ms'}} />
                <div className={`flex-true text-right relative -mb-1 z-[1500]`}>
                    <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    className="bg-transparent inline-flex items-center justify-center p-0 focus:outline-none focus:ring-none"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        {!isOpen ? (
                        <svg
                        className="block h-6 w-6 sm:h-8 sm:w-8 transition-all duration-200"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                        >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.15"
                        d="M4 6h16M4 12h16M4 18h16"
                        />
                        </svg>
                        ) : (
                        <svg
                        className="block h-6 w-6 sm:h-8 sm:w-8 transition-all duration-200 text-contrast"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                        >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.15"
                        d="M6 18L18 6M6 6l12 12"
                        />
                        </svg>
                        )}
                    </button>

                    </div>
                </div>
            </Container>

            
            
            



        </header>

        <MobileMenu menuLinks={menuLinks} isOpen={isOpen} />
        </>
    )
}

export default Menu

const MobileMenu = props => {

    const {isOpen, menuLinks} = props;

    if (!menuLinks) return null

    // const closedStyle = {
    //     transform: 'translateX(150vw)'
    // }

    // const openStyle = {
    //     transform: 'translateX(0)'
    // }

    return (

        <Transition
                show={isOpen}
                enter="transition ease-out duration-[100ms] transform opacity-0"
                enterFrom={`${enterFrom} opacty-0`}
                enterTo={`${enterTo} opacity-100`}
                leave="transition ease-in duration-[100ms] transform opacity-0"
                leaveFrom={`${enterTo} opacity-100`}
                leaveTo={`${enterFrom} opacty-0`}
                className={mobileMenuWrapper}
                >
                <div className={mobileMenu}>
                <div className={`p-4 pt-12 md:p-10 md:pt-20 lg:p-14 lg:pt-36 w-full max-w-6xl flex flex-col justify-center items-start xl:justify-start space-y-5`}>
                {menuLinks.map((link, index) => {
                           
                        if (link.link.includes('#')) return null;

                        return (
                            <Transition.Child
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            key={index}
                            className={`menuitemlink`}

                          >

                              {link.link.includes('http') ? (
                                <a key={index} className={mobileLink} href={link.link} title={link.name} target={`_blank`} rel={`noopener noreferrer`}> { link.name }</a>
                              ) : link.link.includes('#') ? (
                                <AnchorLink key={index} className={mobileLink} to={link.link} title={link.name}>{ link.name }</AnchorLink>
                              ) : (
                                <Link key={index} className={mobileLink} partiallyActive={link.partActive} activeClassName={`${activeLink} pointer-events-none`} to={link.link} title={link.name}> { link.name }</Link>
                              )}

                        </Transition.Child>
                        )
                        })}


                        <Transition.Child
                          enter="transition ease-in-out duration-300 transform"
                          enterFrom="-translate-x-full"
                          enterTo="translate-x-0"
                          className={`${artistLinks} pt-10 pb-10 uppercase text-sm max-w-lg lg:!-mt-32 lg:mb-12 text-contrast`}
                        >
                        <h3 style={{writingMode: 'sideways-lr', display: 'none'}}>Stream</h3>
                        <div className={`grid grid-cols-2 sm:grid-cols-2 gap-x-10 gap-y-3`}>
                          {streamData.map((str, index) => {

                            if (! str.url) return null

                              return (
                                  <span key={index} className={`inline-block text-right`}>
                                    <a className={`underline underline-offset-[3px] hover:opacity-60`} style={{color: 'currentColor', wordSpacing: '0.35rem', textDecorationThickness: '1.5px'}} href={str.url} target={`_blank`} rel={`noopener noreferrer`}>{str.name}</a>
                                  </span>
                            )
                          })}
                        </div>
                        </Transition.Child>

                </div>

            </div>

         </Transition>
    )

}