import React, { useState } from 'react'
import { form, labelText, inputField, selectWrapper, selectField, textareaField, submitButton, dummyUrlField, inSubmission } from "../styles/forms.module.scss"
import axios from "axios"
// const dotenv = require('dotenv');
import Loading from '../svg/Loading';


export default function ContactForm(props) {


    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
      });
      const handleServerResponse = (ok, msg, cForm) => {
        setServerState({
          submitting: false,
          status: { ok, msg }
        });
        if (ok) {
          cForm.reset();
        }
      };
      const handleOnSubmit = e => {
        e.preventDefault();
        const cForm = e.target;
        setServerState({ submitting: true });
        axios({
          method: "post",
          url: process.env.CF_URL,
          data: new FormData(cForm)
        })
          .then(r => {
            handleServerResponse(true, "Thanks. We'll be in touch soon.", cForm);
          })
          .catch(r => {
            handleServerResponse(false, r.response.data.error, cForm);
          });
      };


    const subjectOptions = [
        {
            name: `General inquiry`,
            value: `general`
        },
        {
            name: `Opportunities`,
            value: `opportunities`
        },
        {
            name: `Re: Wishful Thinking`,
            value: `vinyl`
        },
        {
            name: `Other`,
            value: `other`
        }
    ]

    let formClassName = form;
    if (props.className) formClassName = [form, props.className].join(" ");


    return (
        <form className={`${formClassName} ${serverState.submitting === true ? inSubmission : ''}`} onSubmit={handleOnSubmit}>
            <div className={`flex flex-row md:justify-start md:items-end -mx-2 lg:-mx-3 flex-wrap`}>
            
            <div className={`px-2 lg:px-3 flex-1/2 max-w-1/2 w-full`}>


            <label className="inputField__label">
                <span className={labelText}>First Name</span>
                <input
                    type={`text`}
                    placeholder={``}
                    required={true}
                    className={inputField}
                    name={`first-name`}
                />
            </label>
            </div>

            <div className={`px-2 lg:px-3 flex-1/2 max-w-1/2`}>

            <label className="inputField__label">
                <span className={labelText}>Last Name</span>
                <input
                    type={`text`}
                    placeholder={``}
                    required={true}
                    className={inputField}
                    name={`last-name`}
                />
            </label>
            </div>

            <div className={`mt-3 px-2 lg:px-3 lg:max-w-3/5 w-full`}>
                        <div className={`w-full`}>

                <label className="inputField__label">
                <span className={labelText}>Email</span>
                <input
                    type={`email`}
                    placeholder={``}
                    required={true}
                    className={inputField}
                    name={`email`}
                />
            </label>
                        </div>
             </div>

             <div className={`mt-3 px-2 lg:px-3 lg:max-w-2/5 w-full`}>
                        <div className={`w-full`}>


                        <div className={selectWrapper}>
                                <label className="inputField__label" htmlFor={`subject`}><span className={labelText}>Subject</span></label>
                                <select
                                className={selectField}
                                name={`subject`}
                                id={`subject`}
                                >
                                <option value={``}>&nbsp;</option>
                                    {subjectOptions.map((opt, index) => (
                                        <option key={index}  value={opt.name}>{opt.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
             </div>

             <div className={`mt-3 px-2 lg:px-3 w-full ${dummyUrlField}`}>
                        <div className={`w-full`}>

                <label className="inputField__label">
                    <span className={labelText}>Website/URL</span>
                    <input
                    type={`url`}
                    placeholder={``}
                    required={false}
                    className={`url-field`}
                    name={`site-url`}
                    autoComplete={`off`}
                />
                </label>
                 </div>
             </div>

             <div className={`mt-3 px-2 lg:px-3 w-full`}>
                        <div className={`w-full`}>

                <label className="inputField__label">
                    <span className={labelText}>Message</span>
                    <textarea
                        required={true}
                        className={textareaField}
                        rows={10}
                        name={`message`}
                    />
                </label>
                 </div>
             </div>
            </div>

                <input
                className={submitButton}
                type="submit"
                value="send message"
                // disabled={validateInput(props.formValues)}
            />
                    {serverState.status && (
                        <div className="pt-4 text-center text-sm 2xl:text-base">
                                <span className={`${!serverState.status.ok ? "errorMsg" : ""} font-medium tracking-normal`}>
                                {serverState.status.msg}
                                </span>
                        </div>
                            )}
            {serverState.submitting === true &&
            
                <Loading className={`absolute top-0 left-0 right-0 bottom-0 m-auto z-50`} />
            }
        </form>
        
    )
}