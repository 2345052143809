import React from 'react'

export default function Mwj(props) {

    const { fill, ...rest } = props;

    let mainFill = 'currentColor';

    if (fill) mainFill = fill;

    return (
        <svg role="presentation" x="0px" y="0px"
	 viewBox="0 0 872.8 334.3" {...rest}>
        <path fill={mainFill} d="M275.3,147.6c-1,7.3-6.6,20.8-13.9,23.4c-10.9,1.6-14.3-15.5-7.3-21.9C259,141.7,274.5,132.7,275.3,147.6z
	 M528.8,82.5c8.6-17.4-13.4-30.2-23.6,4.2c-18.5,43.5-34.9,88.1-61.1,127.8c-12.3,20.4-27.7,32.6-28.7-2
	c-1.9-11.3,8.8-31.2-2.7-38.5c-7.9-2.3-11.1,8.4-16.3,12.4c-7.6,9.1-17.2,16-26.9,22.7c-16.3,13.8-27.5,22-20.1-7.5
	c10.4-28.5,24.7-55.2,36.4-83.2c7.8-20.7,16.7-64.5,16.7-64.5c-1.6-11.6-8.5-20.5-20.1-25.3c-10.8-3.5-20.2,5.4-29.2,9.4
	c-9,4.5-20.8,10.4-26.5,18c-4.4,7.1-16.1,17.9-14.4,26.4c0.4,1.4,1.4,5.6,6.4,5.1c6.4-1.2,8.7-6.9,13.5-10.7
	c6.3-4.4,14.2-7.1,20.5-11.7c39.4-21,22.2,41,13.7,59.4c-13,35.2-33.1,68.1-40.6,105c-0.9,10.4,5.1,30,18.6,25.8
	c3.3-1.5,6.9-4.1,9.5-6.9c12-11.8,25.9-21.7,39.6-31.5c3.6,9.6-0.4,20.8,1.9,30.8c1.5,10.3,11.3,21.3,22.5,18.8
	c15.5-6.3,26.6-26.1,35.4-39.8c17.7-29.3,32.9-60.1,46.8-91.3c5.5-10.2,7.6-22.4,14-31.9C519.8,97.4,525,89.2,528.8,82.5z
	 M216.4,201.5c-16.8-1.5-30.6,18.9-47.4,19.2c-7.4-4.6-2.4-15.8-2.6-23c-1.2-20.3,7.4-35.4,16-54.2c7.8-19.4,17.9-37.6,22.7-58.4
	c2-9.9,6.4-19.1,5.7-29.2c0.3-9.5-7.5-21.7-17.9-16.3c-18.6,11.8-34.1,33.8-49.6,49.5c-3,1.7-16.3,21.3-16,10.8
	c1-14.6,8.2-28.6-0.3-43.2c-4.7-8.1-14.9-4.6-19.1,1.1c-2.7,3.6-5.6,6.5-10.3,7.2c0.7-10.7,0.5-20.4-10.2-27.7
	C66.1,24.1,34.9,48.7,24,66.9c-2.2,3.1-3.9,6.5-5.4,9.8c-2.5,6,5,11.5,9.9,7.5c9.2-10.9,45.5-31.3,46.4-5.6
	c-3.5,21.8-17.8,40.5-25,61.3c-12.2,25.9-27.4,50.6-40.2,76.2c-3.9,8.3-10.3,18.3-9.6,28.2c10.4,22.3,24.7-25,30.6-31.5
	c19.6-31,33.2-67.5,52.1-98.2c7.2-10.1,13.2-20.3,20.9-27c10.4,6.7-2.4,40.1-4.5,50.8c0.2,9.1-11.1,23.3-1.9,29.5
	c9.8,2.8,12.1-13,17.7-18.5c6.5-9.1,13.2-17.9,19.7-26.1c8.3-9.8,17.4-19,25.4-28.9c8-8.3,14.5-19.9,26-23.6
	c4.8,7.8,1.4,18.2-2,26.4c-1.6,5.8-3.1,11.8-5.6,17.4c-10.9,33.4-32.4,64.6-32.5,100.6c0.2,12.6-5.3,28.7,8.4,36.1
	c9.8,5.6,24.2-6.9,33.6-10.8c6.8-4.7,18.9-7.8,27.1-13.6C221.1,220.1,229.2,205.8,216.4,201.5z M871.6,119.9
	c-8.2,16.8-30.3,8.3-44.7,9.5c-9.6-0.2-19,1.2-28.2,3.9c-20.6,5-39.6,17.1-61,23.2c-28,8.4-58.2,17.9-75,41.3
	c-20.9,35.5-38.3,74.1-68.6,103c-13.2,12.3-26,26.4-42.8,33.6c-4.7,0.1-8.1-4.5-9.9-7.6c-14.8-17.2-1.2-37.5,7.4-53.8
	c15.7-30.4,41.3-49.1,65.4-71.3c13.3-13.9,31.2-21,47.5-30.3c14-11.3,25.4-52.9,28.5-70.9c1.8-15.8,5.3-32.2,1.4-48
	c-2.2-8-3.4-27.2-15.6-25.5C652.7,39,640,61.8,629.8,85.6c-6.3,24.8-17,57.6-0.5,80.1c4.7,4.2,6.1,7.6,2.8,13.2
	c-8.2,21.1-20.5,9.3-25.2-6c-5-15.8-3.6-30.5-2.3-46.4C613,75.9,641,26.2,685.8,0c15.5,1.4,19,20.3,23.9,32.2
	c4.8,18.1,0.3,38.8-1.3,56.9c-3.5,24.3-13.1,43.4-22,65.4c7.6,1,20.7-11.8,30.5-13.6c12.3-4.3,25.6-6.3,37.6-11.4
	c9.3-3.2,17.7-8.8,27.2-11.7c15.8-5.8,31.6-11.1,48-12.3c8.6-1.7,17.6,0.5,26.3,1.1C864,106.6,876.8,109,871.6,119.9z M556.7,309.6
	c14.2,0.2,45.7-35.3,55.3-47.6c9.4-14,18.2-28.6,26.9-42.9c3-5.3,7.4-11.1,7.4-17.1c-24.7,6.1-46.8,33.4-66.2,50.5
	c-11.9,12.3-25.3,25.8-26.4,43.7C553.4,300.2,552.9,307.5,556.7,309.6z M541.7,167.6c4.5-5.6,1.1-21.2-8.5-16.8
	c-6.9,5.1-10.6,14.5-13.5,22.2C522.5,190.4,539.3,178.3,541.7,167.6z"/>
</svg>
    )
}