import React from 'react'
import { wrapper } from "../styles/wrapper.module.scss"

export default function Wrapper(props) {

    return (

        <main className={wrapper}>
           <div>
           {props.children}
           </div>
        </main>
    )
}